import React from 'react';
import clsx from 'clsx';
import * as styles from './Icon.module.css';

export const Icon = ({ icon }) => {
  if (icon) {
    return <span className={clsx(styles.icon, `icon-${icon}`)} />;
  }
  return null;
};

import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import AwardsLogos from './../Footer/Awards';

import * as styles from './Awards.module.css';

function Awards({ style }) {
  const { t } = useTranslation('labels');
  return (
    <div className={styles.content} style={style}>
      <div className={styles.teaser}>
        <h3>{t('awards.title')}</h3>
        <p>
          <em>{t('awards.teaser')}</em>&nbsp;
          {t('awards.text')}
        </p>
      </div>
      <div className={styles.awards}>
        <AwardsLogos withLaurels={false} />
      </div>
    </div>
  );
}

export default Awards;

import React from 'react';

import * as styles from './PageTitle.module.css';

export const PageTitle = ({ title, id, icon, background }) => (
  <div className={background ? styles[background] : styles.title} id={id}>
    {icon ? (
      <h2>
        <span className={`icon-${icon}`}></span> {title}
      </h2>
    ) : (
      <h2>{title}</h2>
    )}
  </div>
);

import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './news.module.css';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Awards from '../components/Common/Awards';
import fecha from 'fecha';
import NewsItemImages from '@mangoart/gatsby-ui/components/ezagrar/NewsItemImages';
import * as style from 'variowelt.at/src/templates/news.module.css';
import NewsForm from '@mangoart/gatsby-ui/components/ezagrar/NewsForm';
import NewsItemForm from '@mangoart/gatsby-ui/components/ezagrar/NewsItemForm';
import VarioweltAcademyForm from '@mangoart/gatsby-ui/components/ezagrar/VarioweltAcademyForm';
import VarioweltAcademyItemForm from '@mangoart/gatsby-ui/components/ezagrar/VarioweltAcademyItemForm';

NewsTemplate.propTypes = {
  pathContext: PropTypes.shape().isRequired,
};

function NewsTemplate({ data }) {
  const { item } = data;
  const { title, teaser, text, date, category, images, videoUrl } = item;
  const that = typeof window !== 'undefined' ? window : this;
  const modifiedText = text.replace(/\/storage\//g, 'https://ezagrar.cms.mangomedia.at/storage/');

  const { t } = useTranslation('labels');

  let pageTitle = t('news.title_news');
  let icon = 'news';
  let backToListLabel = t('news.backlabel_news');
  let backToListLink = '/news';

  if (category === 'Auszeichnung') {
    pageTitle = t('news.title_auszeichnung');
    icon = 'laurel';
    backToListLabel = t('news.backlabel_auszeichnungen');
    backToListLink = '/unternehmen/auszeichnungen';
  } else if (category === 'Stellenausschreibungen') {
    pageTitle = t('news.title_stellenangebot');
    icon = 'team';
    backToListLabel = t('news.backlabel_stellengebot');
    backToListLink = '/unternehmen/stellenangebote';
  }
  /* <PageTransition>
     <HerstellerDetail data={{ manufacturer, produktGruppen }} />
   </PageTransition> */

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={pageTitle} icon={icon} />
        <Helmet>
          <title>{title + ' | ' + pageTitle}</title>
          <meta name="description" content={teaser} />
        </Helmet>
        <div className={styles.news}>
          <div className={styles.right}>
            <h3>{title}</h3>
            <h4>{fecha.format(new Date(date), 'D.M.YYYY')}</h4>
            <div dangerouslySetInnerHTML={{ __html: modifiedText }} />
            {videoUrl && (
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingBottom: '56.25%',
                  marginTop: '2.5em',
                }}
              >
                <iframe
                  title={data.item.id}
                  style={{ position: 'absolute', left: 0, top: 0 }}
                  src={videoUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="auto"
                  allowFullScreen="allowfullscreen"
                />
              </div>
            )}
            {title.toLocaleLowerCase() === 'fliegl tage in der variowelt' && (
              <div className={style.marginTop}>
                <h3 style={{ marginTop: '1em' }}>Anmeldeformular</h3>
                <NewsForm form={NewsItemForm} />
              </div>
            )}
            {false && title.toLocaleLowerCase() === 'variowelt academy 2023' && (
              <div className={style.marginTop}>
                <h3 style={{ marginTop: '1em' }}>Anmeldeformular</h3>
                <VarioweltAcademyForm form={VarioweltAcademyItemForm} />
              </div>
            )}
            <div className={styles.allNewsItemsLink}>
              <Link to={backToListLink}>{backToListLabel}</Link>
            </div>
          </div>
          <div className={styles.left}>
            <NewsItemImages
              images={images.map((node) => {
                return {
                  altText: node.alttext,
                  thumb: getImage(node.image),
                  image: getImage(node.image),
                };
              })}
            />
          </div>
        </div>
        <Awards />
      </div>
    </DefaultLayout>
  );
}

export default NewsTemplate;

export const query = graphql`
  query ($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    item: cockpitNews(id: { eq: $id }) {
      id
      title
      teaser
      text
      date
      videoUrl
      expirationdate
      images {
        image {
          childImageSharp {
            gatsbyImageData(width: 600, placeholder: NONE)
          }
        }
      }
    }
  }
`;
